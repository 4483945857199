import React from 'react'
import Home from './Home'
import About from './About'
import Footer from './Footer'
import Treatment from './Treatment'
import Doctor from './Doctor'
import Blogs from './Blogs'

const Layout = () => {
    return (
        <div className='main'>
            <Home />
            <About />
            <hr/>
            <Blogs/>
            <Doctor/>
            <Treatment/>
            <Footer/>
        </div>
    )
}

export default Layout