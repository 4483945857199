import React from 'react';
import Footer from './Footer';

import gal1 from './img/poster1.jpg';
import gal2 from './img/poster2.jpg';
import gal3 from './img/poster3.jpg';
import gal4 from './img/poster4.jpg';
import gal5 from './img/banner1.jpg';
import gal6 from './img/banner2.jpg';
import gal7 from './img/gal1.jpg';
import gal8 from './img/gal2.jpg';
import gal9 from './img/gal3.jpg';
import gal10 from './img/priyanka.jpg';

import newgal1 from './img/newgal (1).jpeg'
import newgal2 from './img/newgal (2).jpeg'
import newgal3 from './img/newgal (3).jpeg'
import newgal4 from './img/newgal (4).jpeg'
import newgal5 from './img/newgal (5).jpeg'



const Gallery = () => {
  return (
    <>
      <div className="gallery">
        <img src={gal1} alt="Gallery 1" className="gallery-img" />
        <img src={gal2} alt="Gallery 2" className="gallery-img" />
        <img src={gal3} alt="Gallery 3" className="gallery-img" />
        <img src={gal4} alt="Gallery 4" className="gallery-img" />
        <img src={gal5} alt="Gallery 5" className="gallery-img" />
        <img src={gal6} alt="Gallery 6" className="gallery-img" />
        <img src={gal7} alt="Gallery 7" className="gallery-img" />
        <img src={gal8} alt="Gallery 8" className="gallery-img" />
        <img src={gal9} alt="Gallery 9" className="gallery-img" />
        <img src={gal10} alt="Gallery 10" className="gallery-img" />

        <img src={newgal1} alt="Gallery 11" className="gallery-img" />
        <img src={newgal2} alt="Gallery 12" className="gallery-img" />
        <img src={newgal3} alt="Gallery 13" className="gallery-img" />
        <img src={newgal4} alt="Gallery 14" className="gallery-img" />
        <img src={newgal5} alt="Gallery 15" className="gallery-img" />




      </div>
      <Footer />
    </>
  );
};

export default Gallery;
