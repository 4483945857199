import React from 'react'

import blog1 from './img/blog1.jpg'
import blog2 from './img/blog2.jpg'

const Blogs = () => {
    return (
        <div className="container mt-4">
            <div className="row">
                <div className="col-md-6 mb-3">
                    <img src={blog1} alt="Blog 1" className="img-fluid" style={{ height: '300px', width:'300px', objectFit: 'cover' }} />
                </div>

                <div className="col-md-6 mb-3">
                    <img src={blog2} alt="Blog 2" className="img-fluid" style={{ height: '300px', width:'300px', objectFit: 'cover' }} />
                </div>
            </div>

            <div className="blog-content">
                <p>3rd gravida with no living issue with Rh negative pregnancy and precious child successfully delivered at 36 weeks + 2 days on 1 Jan 2025.</p>
                <p>Parents blessed with a beautiful daughter.</p>
            </div>
        </div>
    )
}

export default Blogs
