import React from 'react';
import Card from 'react-bootstrap/Card';

const Doctor = () => {
    return (
        <>
            <hr />
            <center><h1>OUR DOCTORS</h1></center>
            <br />
            <div className='Doctors'>
                
                {/* Doctor 1 */}
                <div className='Doctor2'>
                    <Card style={{ width: '100%', backgroundColor: '#61c4d5' }}>
                        <Card.Body>
                            <Card.Title>Dr. Priyanka Mishra</Card.Title>
                            <Card.Text>MBBS MS OBS GYNEC</Card.Text>
                            <Card.Text>
                                Leading Gynecologist Shaping Women in India. Member of Federation of Obstetricians and Gynecologists Societies. Former Gynecologist at Fatima Hospital, LKO & Hind Medical College Lucknow.
                            </Card.Text>
                            {/* <Card.Text>
                                Specializations: Normal Delivery, Caesarean, Hysterectomy, Laparoscopy, High Risk Pregnancy, Infertility, Menopause, Adolescent Problems, Menstrual Problems, PCOD, Breast Disease and Lumps.
                            </Card.Text> */}
                            <p>Contact: 0522 4625501, 9140474598</p>
                        </Card.Body>
                    </Card>
                </div>

                {/* Doctor 2 */}
                <div className='Doctor2'>
                    <Card style={{ width: '100%', backgroundColor: '#61c4d5' }}>
                        <Card.Body>
                            <Card.Title>Dr. Ravi Kumar</Card.Title>
                            <Card.Text>MBBS DNB General Medicine</Card.Text>
                            <Card.Text>
                                Member of the American College of Physicians (ACP). Faculty in Dept of General Medicine at HIMS Ateria.
                            </Card.Text>
                            <Card.Text>
                                Specializations: General Physician, Hypertension, Diabetic, Fever, Liver & Critical Care Specialist.
                            </Card.Text>
                            <p>Contact: 0522 4625501, 9140474598</p>
                        </Card.Body>
                    </Card>
                </div>

                {/* Doctor 3 */}
                <div className='Doctor2'>
                    <Card style={{ width: '100%', backgroundColor: '#61c4d5' }}>
                        <Card.Body>
                            <Card.Title>Dr. Nirupama Jaiswal</Card.Title>
                            <Card.Text>MBBS MD Psychiatry</Card.Text>
                            <Card.Text>
                                Specialist in Psychiatry.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </div>

                {/* Doctor 4 */}
                <div className='Doctor2'>
                    <Card style={{ width: '100%', backgroundColor: '#61c4d5' }}>
                        <Card.Body>
                            <Card.Title>Dr. Puneet Tulsyan</Card.Title>
                            <Card.Text>MBBS DNB</Card.Text>
                            <Card.Text>
                                Ex. Sr. Child Specialist at KGMU-LUCKNOW.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </div>

                {/* Doctor 5 */}
                <div className='Doctor2'>
                    <Card style={{ width: '100%', backgroundColor: '#61c4d5' }}>
                        <Card.Body>
                            <Card.Title>Dr. Navendu Mohan Gupta</Card.Title>
                            <Card.Text>MBBS MS General & Laparoscopic Surgeon</Card.Text>
                            <Card.Text>
                                Specializing in Thyroid & Breast Cancer surgeries. OPD Time: Evening 5 PM to 7 PM.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </div>

                {/* Doctor 6 */}
                <div className='Doctor2'>
                    <Card style={{ width: '100%', backgroundColor: '#61c4d5' }}>
                        <Card.Body>
                            <Card.Title>Dr. A. K. Maurya</Card.Title>
                            <Card.Text>MBBS DNB Chest, TB & Respiratory Diseases</Card.Text>
                            <Card.Text>
                                Pulmonary and Critical Care Consultant, PDCC KGMU Lucknow.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </div>

            </div>
        </>
    );
}

export default Doctor;
